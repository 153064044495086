import { useState } from 'react';
import { Card, CardHeader, CardContent, Box, Button } from '@mui/material';
import { useNotify } from 'react-admin';
import { REQUESTS_ROUTES } from '../../../config/constants/apiRoutes';
import { BUTTON_OPTIONS } from '../../../config/constants/buttonConstants';
import { HTTP_STATUS } from '../../../config/constants/httpStatusCodes';
import { EVENT_OPTIONS } from '../../../config/constants/trackCbEvents';
import { DateInput } from '../../../components/DateInput';
import { MultiSelectAutocomplete } from '../../../components/MultiSelectAutocomplete';
import { useFetchEntities } from '../../../hooks/useFetchEntities';
import { buildRequestBody, validateForm } from '../../../utils/requestUtils/hashedEmailsRequestUtils';
import { getHashedEmailsCount, handleDownloadCsv } from '../../../utils/fetchUtils/hashedEmailsFetchUtils';

export const DownloadHashedEmailsCard = () => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [brands, setBrands] = useState([]);
  const [eventTypes, setEventTypes] = useState([]);
  const [affiliates, setAffiliates] = useState([]);
  const [offers, setOffers] = useState([]);
  const [showErrors, setShowErrors] = useState(false);

  const notify = useNotify();

  const { entities: brandOptions } = useFetchEntities(REQUESTS_ROUTES.GET_BRANDS);
  const { entities: affiliateOptions } = useFetchEntities(REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES);
  const { entities: offerOptions } = useFetchEntities(REQUESTS_ROUTES.GET_ACTIVE_OFFERS);

  const handleAction = async (actionType) => {
    if (!validateForm({ startDate, endDate, brands, eventTypes })) {
      setShowErrors(true);
      return;
    }

    try {
      const requestBody = buildRequestBody({
        startDate,
        endDate,
        brands,
        eventTypes,
        affiliates,
        offers,
      });

      const { status, json } = await getHashedEmailsCount(requestBody);
      if (status < HTTP_STATUS.OK || status >= HTTP_STATUS.BAD_REQUEST) {
        notify(json?.error || 'Error retrieving data', { type: 'warning' });
        return;
      }

      const rowCount = json?.result?.hashedCustomerEmailsCount || 0;

      if (actionType === BUTTON_OPTIONS.VIEW) {
        notify(`Row count: ${rowCount}`, { type: 'info' });
        return;
      }

      await handleDownloadCsv({ rowCount, fileName: json?.result?.fileName, notify });
    } catch (error) {
      notify(`Error: ${error.message}`, { type: 'error' });
    }
  };

  return (
    <Card variant="outlined" sx={{ px: 3 }}>
      <CardHeader title="Download Hashed Emails List" />
      <CardContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <DateInput
              label="Start date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              error={showErrors && !startDate}
              helperText={showErrors && !startDate && 'Start date is required'}
            />
            <DateInput
              label="End date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              error={showErrors && !endDate}
              helperText={showErrors && !endDate && 'End date is required'}
            />
          </Box>
          <MultiSelectAutocomplete
            id="brand-selector"
            label="Select Brands"
            options={Array.isArray(brandOptions) ? brandOptions : []}
            getOptionLabel={(option) => `${option.id} - ${option.name}`}
            value={brands}
            onChange={setBrands}
            required
            error={showErrors && brands.length === 0}
            helperText={
              showErrors && brands.length === 0 ? 'At least one brand is required' : ''
            }
          />
          <MultiSelectAutocomplete
            id="event-selector"
            label="Select Events"
            options={EVENT_OPTIONS}
            getOptionLabel={(option) => option.name}
            value={eventTypes}
            onChange={setEventTypes}
            required
            error={showErrors && eventTypes.length === 0}
            helperText={
              showErrors && eventTypes.length === 0 ? 'At least one event is required' : ''
            }
          />
          <MultiSelectAutocomplete
            id="affiliate-selector"
            label="Select Affiliates"
            options={Array.isArray(affiliateOptions) ? affiliateOptions : []}
            getOptionLabel={(option) =>
              `${option.affiliate_id} - ${option.affiliate_name}`
            }
            value={affiliates}
            onChange={setAffiliates}
          />
          <MultiSelectAutocomplete
            id="offer-selector"
            label="Select Offers"
            options={Array.isArray(offerOptions) ? offerOptions : []}
            getOptionLabel={(option) =>
              `${option.offer_id} - ${option.offer_name}`
            }
            value={offers}
            onChange={setOffers}
          />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <Button variant="contained" onClick={() => handleAction(BUTTON_OPTIONS.VIEW)}>
              View Row Count
            </Button>
            <Button variant="contained" onClick={() => handleAction(BUTTON_OPTIONS.DOWNLOAD)}>
              Download
            </Button>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
