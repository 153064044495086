import moment from 'moment';

export const formatTTCWithMoment = (ttcMs) => {
  if (!ttcMs) {
    return '-';
  }
  const duration = moment.duration(ttcMs, 'milliseconds');

  const hours = String(duration.hours()).padStart(2, '0');
  const minutes = String(duration.minutes()).padStart(2, '0');
  const seconds = String(duration.seconds()).padStart(2, '0');

  return `${hours}:${minutes}:${seconds}`;
};
