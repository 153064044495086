import { RowsPerPage, DateInOneRow } from '../../components'
import {
   Datagrid,
   TextField,
   List,
   FunctionField,
} from 'react-admin'
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle'
import { BulkActionCopy } from '../../components'
import { useRowStyle } from '../../hooks/useRowStyle'


export const ElasticCronSettingsList = props => {
   const rowClassName = useRowStyle()

   return (
      <List {...props}
            pagination={<RowsPerPage/>}
            className="list-admin-wrapper"
            filters={[]}
            actions={<ToolBarRowStyleToggle />}
            perPage={50}
      >
         <Datagrid rowClick="edit" className={`${rowClassName} datagrid-content`} bulkActionButtons={<BulkActionCopy/>}>
            <TextField source="id" label="ID"/>
            <TextField source="cron_name" label="Cron Name"/>
            <TextField source="status" label="Status"/>
            <TextField source="service_name" label="Service Name"/>
            <FunctionField 
               source="filters" 
               label="Filters" 
               render={record => record.filters ? JSON.stringify(record.filters) : ''} 
            />
            <TextField source="current_id" label="Current ID"/>
            <DateInOneRow source="create_time" label="Create Time" showTime={true}/>
            <DateInOneRow source="update_time" label="Update Time" showTime={true}/>
         </Datagrid>
      </List>
   )
}
