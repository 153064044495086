import { TextField, Autocomplete } from '@mui/material';

export const MultiSelectAutocomplete = ({
  id,
  label,
  options,
  value,
  onChange,
  getOptionLabel,
  required = false,
  error,
  helperText,
  placeholder,
}) => {
  return (
    <Autocomplete
      multiple
      id={id}
      options={options}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={(option, val) => option.id ? option.id === val.id : option === val}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={placeholder}
          fullWidth
          required={required}
          error={error}
          helperText={helperText}
        />
      )}
    />
  );
};
