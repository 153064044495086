import { SelectInput } from 'react-admin';
import { USER_ACTIONS } from '../../config/constants/actionLogsConstants';

export const actionLogsFilters = [
  <SelectInput 
    source="action" 
    label="Action" 
    choices={USER_ACTIONS} 
  />,
];
