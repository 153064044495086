import { Route } from 'react-router-dom';
import { VersionHistoryPage } from '../modules/additional-pages/VersionHistoryPage';
import { ActionsPage } from '../modules/additional-pages/actions/ActionsPage';
import { isAllowedResource } from '../config/auth-provider';

export const getCustomRoutes = (permissions) => [
   <Route exact key="version-history" path="/version-history" element={<VersionHistoryPage />} />,
   isAllowedResource(permissions, 'actions') && (
     <Route key="actions" path="/actions" element={<ActionsPage />} />
   ),
];
