import * as React from 'react'
import {
   List,
   Datagrid,
   TextField, FunctionField,
} from 'react-admin'
import { RowsPerPage, DateInOneRow, BulkActionCopy } from '../../components'
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle'
import { useRowStyle } from '../../hooks/useRowStyle'


export const ConflictSettingList = props => {
   const rowClassName = useRowStyle()

   return (
      <List {...props}
            pagination={<RowsPerPage/>}
            className="list-admin-wrapper"
            actions={<ToolBarRowStyleToggle/>}
            filters={[]}
      >
         <Datagrid rowClick="edit" className={`${rowClassName} datagrid-content`} bulkActionButtons={<BulkActionCopy/>}>
            <TextField source="id" label="Id"/>
            <TextField source="name" label="System Name"/>
            <FunctionField render={record => {
                  const result = [...record.cookies]
                  result.length = 150

                  return result
               }}
               source="cookies"
               label="Conflict Cookies"
               style={{ display: 'block', maxWidth: '450px', wordWrap: 'break-word' }}
            />
            <DateInOneRow source="update_time" label="Update time" showTime={true}/>
         </Datagrid>
      </List>
   )
}
