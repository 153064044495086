import moment from 'moment';

const toUtcIso = (dateStr) => {
  return dateStr ? moment.utc(dateStr).toISOString() : '';
};
const mapIds = (items = []) => items.map(item => item.id);

export const buildRequestBody = ({
  startDate,
  endDate,
  brands,
  eventTypes,
  affiliates,
  offers,
}) => ({
  brandIds: mapIds(brands),
  affiliateIds: mapIds(affiliates),
  offerIds: mapIds(offers),
  eventTypes: mapIds(eventTypes),
  dateStart: toUtcIso(startDate),
  dateEnd: toUtcIso(endDate),
});

export const validateForm = ({ startDate, endDate, brands, eventTypes }) =>
  Boolean(startDate && endDate && brands.length > 0 && eventTypes.length > 0);
