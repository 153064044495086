import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
} from 'react-admin';
import {
  RowsPerPage,
  DateInOneRow,
  BulkActionCopy,
} from '../../components';
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle';
import { useRowStyle } from '../../hooks/useRowStyle';
import { useFetchEntities } from '../../hooks/useFetchEntities';
import { useLocalStorageEffect } from '../../hooks/useLocalStorageEffect';
import { useRedirectFromParams } from '../../hooks/useRedirectFromParams';
import { getRowWithLink, copyLocalStorageValues } from '../../utils/helpers';
import { getBlockEventAggregatedFilters } from '../../utils/filters/blockEventSettingsFilters';

export const BlockEventSettingsAggregatedList = (props) => {
  const rowClassName = useRowStyle();
  const { entities: brands, total: brandsCount } = useFetchEntities('brand');

  const navigate = useNavigate();

  const handleRowClick = (id, basePath, record) => {
    const queryParams = copyLocalStorageValues({
      sourceKey: 'RaStore.block-event-settings-aggregated.listParams',
      targetKey: 'RaStore.block-event-settings.listParams',
      entityId: JSON.stringify({ brand_id: record.brand_id }),
    });

    navigate(`/block-event-settings${queryParams}`);
  };

  useEffect(() => {
    localStorage.setItem('aggregate', 'brand_id');
  }, []);

  useLocalStorageEffect('RaStore.block-event-settings-aggregated.listParams', 'brand_id');
  useRedirectFromParams('brand_id', 'block-event-settings');

  return (
    <List
      {...props}
      pagination={<RowsPerPage />}
      filters={getBlockEventAggregatedFilters(brandsCount)}
      className="list-admin-wrapper"
      actions={<ToolBarRowStyleToggle
        createButtonUrl='/block-event-settings/create'
        tableName='block-event-settings'
      />}
      title="Block-event-settings-aggregated"
    >
      <Datagrid
        rowClick={handleRowClick}
        className={`${rowClassName} datagrid-content`}
        bulkActionButtons={<BulkActionCopy showCopyButton={false} showDeleteButton={false} />}
      >
        <TextField source="count" />
        <TextField label="Type" />
        <FunctionField
          source="brand_id"
          label="Brand"
          render={(record) => getRowWithLink('brand', record.brand_id, brands)}
        />
        <TextField label="Transaction ID" />
        <TextField label="Visit ID" />
        <TextField label="Customer ID" />
        <TextField label="Customer Email" />
        <TextField label="Customer ip" />
        <TextField label="Hashed Email Check" />
        <TextField label="Zero Sale Amount Check" />
        <TextField label="Track event VID NULL" />
        <DateInOneRow
          source="update_time"
          label="Update time"
          showTime={true}
        />
      </Datagrid>
    </List>
  );
};
