import { Title } from 'react-admin';
import { Box, Grid } from '@mui/material';
import { DownloadHashedEmailsCard } from './DownloadHashedEmailsCard';

export const ActionsPage = () => {
  return (
    <Box p={3}>
      <Title title="Actions" />
      <Grid container spacing={2}>
        <Grid item xs={12} md={5}>
          <DownloadHashedEmailsCard />
        </Grid>
      </Grid>
    </Box>
  );
};
