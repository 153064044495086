import {
   List,
   Datagrid,
   TextField,
} from 'react-admin'
import {
   RowsPerPage,
   FixTypeNames,
   ContextParser,
   DateInOneRow,
   BulkActionCopy,
} from '../../components'
import { useRowStyle } from '../../hooks/useRowStyle'
import { useRedirectIfNoFilter } from '../../hooks/useRedirectIfNoFilter'
import { useFetchEntities } from '../../hooks/useFetchEntities'
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle'
import { compareAggregateValue } from '../../utils/aggregateTablesUtils'
import { getNotificationEmailsFilters } from '../../utils/filters/notificationEmailsFilters';

export const NotificationsEmailsList = props => {
   const rowClassName = useRowStyle();
   const { total: brandsCount } = useFetchEntities('brand');
   const shouldRedirect = compareAggregateValue('notification_emails_list');

   useRedirectIfNoFilter({
      filterKey: 'context',
      redirectLink: 'notification-emails-aggregated',
      sourceKey: 'RaStore.notification-emails.listParams',
      targetKey: 'RaStore.notification-emails-aggregated.listParams',
      shouldRedirect
   });

   return (
      <List {...props}
            pagination={<RowsPerPage/>}
            filters={getNotificationEmailsFilters(brandsCount)}
            className="list-admin-wrapper"
            actions={<ToolBarRowStyleToggle tableName='notification-emails' />}
      >
         <Datagrid rowClick="edit" className={`${rowClassName} datagrid-content`} bulkActionButtons={<BulkActionCopy/>}>
            <TextField source="id" label="ID"/>
            <TextField source="sent_success" label="Sent success"/>
            <TextField source="context.plugin_status" label="Plugin status" sortBy="context"/>
            <TextField source="subject" label="Subject"/>
            <TextField source="template" label="Template"/>
            <ContextParser source="context" field="domain" label="Brand Domain" sortBy="context.domain"/>
            <ContextParser source="context" field="id" label="Brand" sortBy="context.id"/>
            <FixTypeNames source="context" label="Integration type" sortBy="context.type"/>
            <DateInOneRow source="update_time" label="Update time" showTime={true}/>
         </Datagrid>
      </List>
   )
}
