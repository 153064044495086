import * as React from 'react'
import {
   List,
   Datagrid,
   TextField,
} from 'react-admin'

import { RowsPerPage, DateInOneRow, BulkActionCopy } from '../../components'
import { useRowStyle } from '../../hooks/useRowStyle'
import { ActionsWithoutCreate } from '../../components'

export const CurrencyList = props => {
   const rowClassName = useRowStyle()

   return (
      <List {...props}
            pagination={<RowsPerPage/>}
            className="list-admin-wrapper"
            actions={<ActionsWithoutCreate/>}
            filters={[]}
      >
         <Datagrid className={`${rowClassName} datagrid-content`} bulkActionButtons={<BulkActionCopy showCopyButton={false} showDeleteButton={false}/>}>
            <TextField source="id" label="ID"/>
            <TextField source="base" label="Base"/>
            <TextField source="rate" label="Rate"/>
            <DateInOneRow source="update_time" label="Update time" showTime/>
         </Datagrid>
      </List>
   )
}
