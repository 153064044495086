import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
   List,
   Datagrid,
   TextField,
} from 'react-admin'
import { RowsPerPage, DateInOneRow, EntityIdWithName } from '../../components'
import { useRowStyle } from '../../hooks/useRowStyle'
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle'
import { BulkActionCopy } from '../../components/BulkActionCopy'
import { useLocalStorageEffect } from '../../hooks/useLocalStorageEffect';
import { useRedirectFromParams } from '../../hooks/useRedirectFromParams';
import { copyLocalStorageValues } from '../../utils/helpers';
import { goalsAggregatedFilters } from '../../utils/filters/goalsFilters';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const GoalAggregatedList = props => {
   const rowClassName = useRowStyle()
   const navigate = useNavigate();

   const handleRowClick = (id, basePath, record) => {
      if (!record.offer_id) {
         localStorage.setItem('aggregate', 'goals_list');
         navigate(`/goals`);
      } else {
         const queryParams = copyLocalStorageValues({
            sourceKey: 'RaStore.goals-aggregated.listParams',
            targetKey: 'RaStore.goals.listParams',
            entityId: JSON.stringify({ offer_id: record.offer_id }),
         });
   
         navigate(`/goals${queryParams}`);
      }
   };
  
   useEffect(() => {
      localStorage.setItem('aggregate', 'offer_id');
   }, []);
  
   useLocalStorageEffect('RaStore.goals-aggregated.listParams', 'offer_id');
   useRedirectFromParams('offer_id', 'goals');

   return (
      <List
         {...props}
         perPage={25}
         pagination={<RowsPerPage/>}
         filters={goalsAggregatedFilters}
         className="list-admin-wrapper"
         actions={<ToolBarRowStyleToggle
            tableName='goals'
            createButtonUrl='/goals/create'
            showRefreshEntitiesButton
         />}
         title="Goals-Aggregated"
      >
         <Datagrid
            rowClick={handleRowClick}
            className={`${rowClassName} datagrid-content`}
            bulkActionButtons={<BulkActionCopy showCopyButton={false} showDeleteButton={false} />}
         >
            <TextField source="count" />
            <TextField label="Title"/>
            <TextField label="Event type"/>
            <TextField label="Affiliate" />
            <EntityIdWithName
               source="offer_id"
               reference={REQUESTS_ROUTES.GET_ALL_OFFERS}
               targetSource="offer_name"
            />
            <TextField label="Postback URL" />
            <DateInOneRow source="update_time" label="Update time" showTime/>
         </Datagrid>
      </List>
   )
}
