import {
   Create,
   SimpleForm,
   TextInput,
   SelectInput,
   number,
   ReferenceArrayInput,
   AutocompleteArrayInput,
   required,
   useNotify,
   useRedirect
} from 'react-admin'
import { ActionButton } from '../../components'
import { ToolbarAlwaysOnSaveButton } from '../../components/ToolbarAlwaysOnSaveButton'
import { PreApprovalSettings } from '../../config/constants'
import { setChoices } from '../../utils/setChoices'
import { validateDecimal } from '../../utils/helpers'
import {
   preApprovalSettingsCheckers,
   preApprovalSettingsStatuses,
   preApprovalSettingsCommission,
} from '../../config/constants';
import { useProcessCopiedRecord } from '../../hooks/useProcessCopiedRecord';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const PreApprovalSettingsCreate = props => {
   const notify = useNotify();
   const redirect = useRedirect();

   const onSuccess = () => {
      notify('Pre-approval settings created successfully', { type: 'success' });
      redirect('/pre-approval-settings');
   };

   return (
      <>
         <ActionButton type="back" />
         <Create 
            resource="pre-approval-settings/bulk-create" 
            {...props} 
            mutationOptions={{ onSuccess }} 
            record={useProcessCopiedRecord('pre-approval-settings') || null}
            title="Create Pre-approval Settings"
         >
            <SimpleForm toolbar={<ToolbarAlwaysOnSaveButton/>}>
               <ReferenceArrayInput
                  label="Affiliates"
                  source="affiliate_ids"
                  reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
                  sort={{field: "affiliate_id", order: "ASC"}}
               >
                  <AutocompleteArrayInput
                     style={{ minWidth: '680px'}}
                     optionText={(choice) => `${choice.affiliate_id} - ${choice.affiliate_name}`}
                  />
               </ReferenceArrayInput>
               <ReferenceArrayInput
                  label="Offers"
                  source="offer_ids"
                  reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
                  sort={{field: "offer_id", order: "ASC"}}
               >
                  <AutocompleteArrayInput
                     style={{ minWidth: '680px'}}
                     optionText={(choice) => `${choice.offer_id} - ${choice.offer_name}`}
                  />
               </ReferenceArrayInput>
               <SelectInput 
                  source="checker"
                  label="Checker"
                  choices={setChoices(preApprovalSettingsCheckers)}
               />
               <TextInput source="above_sale_amount" validate={validateDecimal} />
               <TextInput source="below_sale_amount" validate={validateDecimal} />
               <SelectInput source="result" choices={setChoices(PreApprovalSettings.decisionResult)}/>
               <SelectInput
                  source="status"
                  label="Status"
                  choices={setChoices(preApprovalSettingsStatuses)}
                  validate={[required()]}
               />
               <SelectInput
                  source="fallback_result"
                  choices={setChoices(PreApprovalSettings.decisionFallbackResult)}
               />
               <SelectInput
                  source="commission_structure"
                  choices={setChoices(preApprovalSettingsCommission)}
                  style={{ minWidth: '215px'}}
               />
               <TextInput source="untracked_period" validate={[number()]} />
            </SimpleForm>
         </Create>
      </>
   )
}
