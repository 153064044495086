import { TextField } from '@mui/material';

export const DateInput = ({ label, value, onChange, error, helperText }) => (
  <TextField
    label={label}
    type="date"
    value={value}
    onChange={onChange}
    InputLabelProps={{ shrink: true }}
    fullWidth
    required
    error={error}
    helperText={helperText}
  />
);
