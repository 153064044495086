import * as React from 'react'
import {
  List,
  Datagrid,
  TextField,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  FunctionField,
} from 'react-admin'
import {
  RowsPerPage,
  EntityIdWithName,
  DateInOneRow,
  BulkActionCopy,
} from '../../components'
import { useRowStyle } from '../../hooks/useRowStyle'
import { useFetchEntities } from '../../hooks/useFetchEntities'
import { getRowWithLink } from '../../utils/helpers'
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle'
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const BrandTagsList = props => {
  const rowClassName = useRowStyle()
  const { entities: brands, total } = useFetchEntities('brand')

  const filters = [
    <TextInput
      source="search"
      label="ID, Brand (ID), Affiliate, Offer, Tag url"
      className="search-input-width"
      alwaysOn
    />,
    <ReferenceInput
      label="Brand"
      source="brand_id"
      reference="brand"
      sort={{field: "name", order: 'ASC'}}
      perPage={total}
    >
      <AutocompleteInput className="search-input-width" optionText={(choice) => choice.name} />
    </ReferenceInput>,
    <ReferenceInput
      label="Affiliate"
      source="affiliate_id"
      reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
      sort={{field: "affiliate_name", order: "ASC"}}
    >
      <AutocompleteInput className="search-input-width" optionText={(choice) => choice.affiliate_name} />
    </ReferenceInput>,
    <ReferenceInput
      label="Offer"
      source="offer_id"
      reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
      sort={{field: "offer_name", order: "ASC"}}
    >
      <AutocompleteInput className="search-input-md" optionText={(choice) => choice.offer_name} />
    </ReferenceInput>,
  ]

  return (
    <List
      {...props}
      pagination={<RowsPerPage/>}
      filters={filters}
      className="list-admin-wrapper"
      actions={<ToolBarRowStyleToggle/>}
    >
      <Datagrid rowClick="edit" className={`${rowClassName} datagrid-content`} bulkActionButtons={<BulkActionCopy/>}>
        <TextField source="id" label="ID"/>
        <FunctionField
          source="brand_id"
          label="Brand"
          render={record => getRowWithLink('brand', record.brand_id, brands)}
        />
        <EntityIdWithName
          source="affiliate_id"
          reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
          targetSource="affiliate_name"
          emptyText="Null"
        />
        <EntityIdWithName
          source="offer_id"
          reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
          targetSource="offer_name"
          emptyText="Null"
        />
        <TextField source="tag_url"/>
        <DateInOneRow source="update_time" label="Update time" showTime={true}/>
      </Datagrid>
    </List>
  )
}
