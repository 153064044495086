import {
   List,
   Datagrid,
   TextField,
   TextInput,
   BooleanField,
   SelectInput,
   ReferenceInput,
   AutocompleteInput,
   FunctionField,
} from 'react-admin'
import {
   RowsPerPage,
   DateInOneRow,
   YesOrNoField,
   BulkActionCopy,
} from '../../components'
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle'
import { useRowStyle } from '../../hooks/useRowStyle'
import { useFetchEntities } from '../../hooks/useFetchEntities'
import { useRedirectIfNoFilter } from '../../hooks/useRedirectIfNoFilter'
import { getRowWithLink } from '../../utils/helpers'
import { compareAggregateValue } from '../../utils/aggregateTablesUtils'

export const BlockEventSettingsList = props => {
   const rowClassName = useRowStyle()
   const { entities: brands, total } = useFetchEntities('brand')
   const shouldRedirect = compareAggregateValue('block_event_settings_list');

   useRedirectIfNoFilter({
      filterKey: 'brand_id',
      redirectLink: 'block-event-settings-aggregated',
      sourceKey: 'RaStore.block-event-settings.listParams',
      targetKey: 'RaStore.block-event-settings-aggregated.listParams',
      shouldRedirect
   });

   const filters = [
      <TextInput
         source="search"
         label="Brand ID, Transaction ID, Visit ID, Customer ID, Customer Email"
         alwaysOn
         style={{width: '565px'}}
      />,
      <ReferenceInput
         label="Brand"
         source="brand_id"
         reference="brand"
         sort={{field: "name", order: 'ASC'}}
         perPage={total}
      >
         <AutocompleteInput className="search-input-width" optionText={(choice) => choice.name} />
      </ReferenceInput>,
      <SelectInput source="hashed_email_check" label="Hashed Email Check" choices={[
         {id: 1, name: 'Yes'},
         {id: 'null', name: 'No'},
      ]}/>,
   ]
   
   return (
      <List
         {...props}
         pagination={<RowsPerPage/>}
         filters={filters}
         className="list-admin-wrapper"
         actions={<ToolBarRowStyleToggle tableName='block-event-settings' />}
      >
         <Datagrid rowClick="edit" className={`${rowClassName} datagrid-content`} bulkActionButtons={<BulkActionCopy/>}>
            <TextField source="id"/>
            <TextField source="type"/>
            <FunctionField
               source="brand_id"
               label="Brand"
               render={record => getRowWithLink('brand', record.brand_id, brands)}
            />
            <TextField source="transaction_id" label="Transaction ID"/>
            <TextField source="visit_id" label="Visit ID"/>
            <TextField source="customer_id" label="Customer ID"/>
            <TextField source="customer_email"/>
            <TextField source="customer_ip"/>
            <YesOrNoField source="hashed_email_check" label="Hashed Email Check"/>
            <BooleanField source="zero_sale_amount" label="Zero Sale Amount Check"/>
            <BooleanField source="track_event_vid_null" label="Track event VID NULL"/>
            <DateInOneRow source="update_time" label="Update time" showTime={true}/>
         </Datagrid>
      </List>
   )
}
