import * as React from 'react'
import {
   List,
   Datagrid,
   TextField,
   FunctionField,
   ReferenceInput,
   AutocompleteInput,
} from 'react-admin'
import { RowsPerPage, ActionsWithoutCreate, BulkActionCopy } from '../../components'
import { useRowStyle } from '../../hooks/useRowStyle'
import { useFetchEntities } from '../../hooks/useFetchEntities'
import { getRowWithLink } from '../../utils/helpers'
// import { useRedirectIfNoFilter } from '../../hooks/useRedirectIfNoFilter'
// import { compareAggregateValue } from '../../utils/aggregateTablesUtils'

export const NotificationsList = props => {
   const rowClassName = useRowStyle()
   const { entities: brands, total } = useFetchEntities('brand')

   const filters = [
      <ReferenceInput
         label="Brand"
         source="brand_id"
         reference="brand"
         sort={{field: "name", order: 'ASC'}}
         perPage={total}
      >
         <AutocompleteInput className="search-input-md" optionText={(choice) => choice.name} />
      </ReferenceInput>,
   ]

   // const shouldRedirect = compareAggregateValue('notifications_list');

   // useRedirectIfNoFilter({
   //    filterKey: 'brand_id',
   //    redirectLink: 'notifications-aggregated',
   //    sourceKey: 'RaStore.notifications.listParams',
   //    targetKey: 'RaStore.notifications-aggregated.listParams',
   //    shouldRedirect
   // });

   return (
      <List
         {...props}
         pagination={<RowsPerPage/>}
         className="list-admin-wrapper"
         actions={<ActionsWithoutCreate 
            // tableName='notifications' 
         />}
         filters={filters}
      >
         <Datagrid className={`${rowClassName} datagrid-content`} bulkActionButtons={<BulkActionCopy showCopyButton={false} showDeleteButton={false} />}>
            <TextField source="id" label="ID"/>
            <TextField source="notification_type" label="Notification Type"/>
            <FunctionField
               source="brand_id"
               label="Brand"
               render={record => getRowWithLink('brand', record.brand_id, brands)}
            />
            <FunctionField label="Cookie String" render={record => JSON.parse(record.params)['cookieString']}/>
         </Datagrid>
      </List>
   )
}
