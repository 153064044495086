import {
   List,
   Datagrid,
   TextField,
   FunctionField,
} from 'react-admin'
import {
   RowsPerPage,
   EntityIdWithName,
   DateInOneRow,
   ActionsWithoutCreate,
   BulkActionCopy,
} from '../../components'
import { useRowStyle } from '../../hooks/useRowStyle'
import { useFetchEntities } from '../../hooks/useFetchEntities'
import { useRedirectIfNoFilter } from '../../hooks/useRedirectIfNoFilter'
import { getRowWithLink } from '../../utils/helpers'
import { aggregatedTablesPaths } from '../../config/constants'
import { compareAggregateValue } from '../../utils/aggregateTablesUtils'
import { getOrderApprovalFilters } from '../../utils/filters/orderApprovalFilters';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const OrderApprovalList = props => {
   const rowClassName = useRowStyle()
   const { entities: brands, total: brandsCount} = useFetchEntities('brand');
   const aggregateValue = localStorage.getItem('aggregate') || '';
   const redirectLink = aggregatedTablesPaths['order-approval'][aggregateValue] || 'order-approval';
   const shouldRedirect = compareAggregateValue('order_approval_list');

   useRedirectIfNoFilter({
      filterKey: aggregateValue,
      redirectLink: redirectLink,
      sourceKey: 'RaStore.order-approval.listParams',
      targetKey: `RaStore.${redirectLink}.listParams`,
      shouldRedirect
   });

   return (
      <List
         {...props}
         pagination={<RowsPerPage />}
         filters={getOrderApprovalFilters(brandsCount)}
         className="list-admin-wrapper"
         actions={<ActionsWithoutCreate tableName='order-approval' />}
      >
         <Datagrid
            className={`${rowClassName} datagrid-content`}
            bulkActionButtons={<BulkActionCopy showCopyButton={false} />}
         >
            <TextField source="id" />
            <FunctionField
               source="brand_id"
               label="Brand"
               render={record => getRowWithLink('brand', record.brand_id, brands)}
            />
            <TextField source="event_id" />
            <TextField source="decision" />
            <TextField source="order_id" />
            <EntityIdWithName
               source="affilate_id"
               reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
               targetSource="affiliate_name"
            />
            <EntityIdWithName
               source="offer_id"
               reference={REQUESTS_ROUTES.GET_ACTIVE_OFFERS}
               targetSource="offer_name"
            />
            <TextField source="amount" />
            <DateInOneRow source="update_time" label="Update time" showTime={true} />
         </Datagrid>
      </List>
   )
}
