import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
} from 'react-admin';
import {
  RowsPerPage,
  EntityIdWithName,
  DateInOneRow,
  ActionsWithoutCreate,
  BulkActionCopy,
} from '../../components';
import { useRowStyle } from '../../hooks/useRowStyle';
import { useFetchEntities } from '../../hooks/useFetchEntities';
import { useLocalStorageEffect } from '../../hooks/useLocalStorageEffect';
import { useRedirectFromParams } from '../../hooks/useRedirectFromParams';
import { getRowWithLink, copyLocalStorageValues } from '../../utils/helpers';
import { aggregatedTablesPaths } from '../../config/constants';
import { getOrderApprovalAggregatedFilters } from '../../utils/filters/orderApprovalFilters';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const OrderApprovalAggregatedList = (props) => {
  const rowClassName = useRowStyle();
  const { entities: brands, total: brandsCount } = useFetchEntities('brand');
  const navigate = useNavigate();
  const aggregateValue = localStorage.getItem('aggregate') || '';
  const redirectLink = aggregatedTablesPaths['order-approval'][aggregateValue] || 'order-approval';

  const handleRowClick = (id, basePath, record) => {
    const queryParams = copyLocalStorageValues({
      sourceKey: `RaStore.${redirectLink}.listParams`,
      targetKey: 'RaStore.order-approval.listParams',
      entityId: JSON.stringify({ [aggregateValue]: record[aggregateValue] }),
    });

    navigate(`/order-approval${queryParams}`);
  };

  useEffect(() => {
    if (!aggregateValue || aggregateValue === 'order_approval_list') {
      localStorage.setItem('aggregate', 'brand_id');
    }
  }, []);

  useLocalStorageEffect(`RaStore.${redirectLink}.listParams`, aggregateValue);
  useRedirectFromParams(aggregateValue, redirectLink);

  return (
    <List
      {...props}
      pagination={<RowsPerPage />}
      filters={getOrderApprovalAggregatedFilters(aggregateValue, brandsCount)}
      className="list-admin-wrapper"
      actions={<ActionsWithoutCreate tableName='order-approval' />}
      title="Order-approval-aggregated"
    >
      <Datagrid
        className={`${rowClassName} datagrid-content`}
        rowClick={handleRowClick}
        bulkActionButtons={<BulkActionCopy showCopyButton={false} showDeleteButton={false} />}
      >
        <TextField source="count" />
        <FunctionField
          source="brand_id"
          label="Brand"
          render={(record) => getRowWithLink('brand', record.brand_id, brands)}
        />
        <TextField label="Event" />
        <TextField label="Decision" />
        <TextField label="Order" />
        <EntityIdWithName
          source="affilate_id"
          reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
          targetSource="affiliate_name"
        />
        <TextField label="Offer" />
        <TextField label="Amount" />
        <DateInOneRow
          source="update_time"
          label="Update time"
          showTime={true}
        />
      </Datagrid>
    </List>
  );
};
