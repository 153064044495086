import { useNavigate } from 'react-router-dom';
import {
  List,
  Datagrid,
  TextField,
} from 'react-admin';
import { RowsPerPage, DateInOneRow, EntityIdWithName } from '../../components';
import { aggregatedTablesPaths } from '../../config/constants';
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle';
import { useRowStyle } from '../../hooks/useRowStyle';
import { useLocalStorageEffect } from '../../hooks/useLocalStorageEffect';
import { useRedirectFromParams } from '../../hooks/useRedirectFromParams';
import { BulkActionCopy } from '../../components/BulkActionCopy';
import { copyLocalStorageValues } from '../../utils/helpers';
import { getPreApprovalAggregatedFilters } from '../../utils/filters/preapprovalSettingsFilters';
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const PreApprovalSettingsAggregatedList = (props) => {
  const rowClassName = useRowStyle();
  const navigate = useNavigate();
  const aggregateValue = localStorage.getItem('aggregate') || '';
  const redirectLink = aggregatedTablesPaths['pre-approval-settings'][aggregateValue] || 'pre-approval-settings';

  const handleRowClick = (id, basePath, record) => {
    const queryParams = copyLocalStorageValues({
      sourceKey: `RaStore.${redirectLink}.listParams`,
      targetKey: 'RaStore.pre-approval-settings.listParams',
      entityId: JSON.stringify({ [aggregateValue]: record[aggregateValue] }),
    });

    navigate(`/pre-approval-settings${queryParams}`);
  };

  useLocalStorageEffect(`RaStore.${redirectLink}.listParams`, aggregateValue);
  useRedirectFromParams(aggregateValue, redirectLink);

  return (
    <List
      {...props}
      pagination={<RowsPerPage />}
      filters={getPreApprovalAggregatedFilters(aggregateValue)}
      className="list-admin-wrapper"
      actions={<ToolBarRowStyleToggle
        createButtonUrl='/pre-approval-settings/create'
        tableName='pre-approval-settings'
      />}
    >
      <Datagrid
        rowClick={handleRowClick}
        className={`${rowClassName} datagrid-content`}
        bulkActionButtons={<BulkActionCopy showCopyButton={false} showDeleteButton={false} />}
      >
        <TextField source="count" />
        <EntityIdWithName
          source="affiliate_id"
          reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
          targetSource="affiliate_name"
          style={{
            maxWidth: '150px',
            wordWrap: 'break-word',
          }}
        />
        <EntityIdWithName
          source="offer_id"
          reference={REQUESTS_ROUTES.GET_ALL_OFFERS}
          targetSource="offer_name"
        />
        <TextField label="Checker" />
        <TextField label="Above sale amount" />
        <TextField label="Below sale amount" />
        <TextField label="Result" />
        <TextField label="Status" />
        <TextField label="Fallback result" />
        <TextField label="Commission structure" />
        <TextField label="Untracked period" />
        <DateInOneRow
          source="update_time"
          label="Update time"
          showTime={true}
          style={{
            maxWidth: '150px',
            wordWrap: 'break-word',
          }}
        />
      </Datagrid>
    </List>
  );
};
