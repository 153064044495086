import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './index.css'
import { BrowserRouter } from 'react-router-dom'

const rootElement = document.getElementById('root')

const root = ReactDOM.createRoot(rootElement)

root.render(
    <BrowserRouter>
        <div className="zoom-container">
            <React.StrictMode>
                <App/>
            </React.StrictMode>
        </div>
    </BrowserRouter>
)
