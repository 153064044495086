import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  ReferenceField,
  SelectField
} from 'react-admin';
import { Tooltip } from '@mui/material';
import { USER_ACTIONS } from '../../config/constants/actionLogsConstants';
import {
  RowsPerPage,
  DateInOneRow,
  BulkActionCopy,
  ActionsWithoutCreate
} from '../../components';
import { useRowStyle } from '../../hooks/useRowStyle';
import { formatJson } from '../../utils/jsonUtils';
import { formatTTCWithMoment } from '../../utils/timeUtils';
import { actionLogsFilters } from '../../utils/filters/actionLogsFilters';

export const ActionLogsList = (props) => {
  const rowClassName = useRowStyle();

  return (
    <List
      {...props}
      pagination={<RowsPerPage />}
      className="list-admin-wrapper"
      actions={<ActionsWithoutCreate />}
      filters={actionLogsFilters}
    >
      <Datagrid
        className={`${rowClassName} datagrid-content`}
        bulkActionButtons={
          <BulkActionCopy showCopyButton={false} showDeleteButton={false} />
        }
      >
        <TextField source="id" label="ID" />
        <ReferenceField
          source="user_id"
          reference="users"
          label="User"
          link="show"
        >
          <FunctionField
            render={(userRecord) =>
              userRecord
                ? `${userRecord.id} - ${userRecord.username}`
                : '-'
            }
          />
        </ReferenceField>
        <SelectField source="action" label="Action" choices={USER_ACTIONS} />
        <FunctionField
            label="Details"
            source="details"
            render={record => (
              <Tooltip
                title={
                  <pre style={{ maxHeight: '600px', overflowY: 'auto' }}>
                    {formatJson(record.details)}
                  </pre>
                }
                placement='left'
                arrow
                classes={{ popper: 'custom-tooltip' }}
              >
                <div style={{
                  width: '280px',
                  wordWrap: 'break-word',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                }}>
                  {record.details ? JSON.stringify(record.details) : '-'}
                </div>
              </Tooltip>
            )}
          />
        <FunctionField
          label="TTC"
          render={record => formatTTCWithMoment(record.ttc)}
        />
        <DateInOneRow source="date" label="Date" showTime />
      </Datagrid>
    </List>
  );
};
