import {
  List,
  Datagrid,
  TextField,
  FunctionField,
} from 'react-admin';
import {
  RowsPerPage,
  BulkActionCopy,
  ActionsWithoutCreate,
  FormattedFieldWithTooltip
} from '../../components';
import { useRowStyle } from '../../hooks/useRowStyle';
import { offersManagementFilters } from '../../utils/filters/offersManagementFilters';

export const OffersManagementList = (props) => {
  const rowClassName = useRowStyle();

  return (
    <List
      {...props}
      pagination={<RowsPerPage />}
      filters={offersManagementFilters}
      className="list-admin-wrapper"
      actions={<ActionsWithoutCreate showRefreshEntitiesButton showLastTuneUpdate />}
    >
      <Datagrid
        rowClick="edit"
        className={`${rowClassName} datagrid-content`}
        bulkActionButtons={<BulkActionCopy showCopyButton={false} showDeleteButton={false} />}
      >
        <TextField source="offer_id" label="Offer ID" />
        <TextField source="offer_name" label="Offer" />
        <TextField source="advertiser_id" label="Advertiser ID" />
        <TextField source="advertiser_name" label="Advertiser" />
        <FunctionField label="Channels" render={(record) => FormattedFieldWithTooltip(record.channels)} />
        <FunctionField label="Tags" render={(record) => FormattedFieldWithTooltip(record.tags)} />
        <FunctionField label="Categories" render={(record) => FormattedFieldWithTooltip(record.categories)} />
        <FunctionField label="Conversion Types" render={(record) => FormattedFieldWithTooltip(record.conversion_types)} />
        <FunctionField label="Included Locations (countries)" render={(record) => FormattedFieldWithTooltip(record.locations)} />
      </Datagrid>
    </List>
  );
};
