import { httpClient, apiUrl } from '../../config/http-client';
import { fileStatuses } from '../../config/constants/fileConstants';
import { downloadFileFromBlob } from '../fileUtils';

export const getHashedEmailsCount = async (requestBody) =>
  httpClient(`${apiUrl}/customer/get-customer-hashed-emails-count`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
  });

export const fetchCsvOrStatus = async (fileName) => {
  const response = await fetch(
    `${apiUrl}/customer/download-customer-hashed-emails-csv?fileName=${fileName}`,
    {
      method: 'GET',
      credentials: 'include',
    }
  );

  if (response.headers.get('content-type').includes('application/json')) {
    const data = await response.json();
    return {
      isFile: false,
      data,
      status: response.status,
    };
  }

  const blob = await response.blob();
  return {
    isFile: true,
    blob,
    status: response.status,
  };
};

const handleFileStatus = ({ fileStatus, error, notify }) => {
  switch (fileStatus) {
    case fileStatuses.IN_PROGRESS:
      notify('File generation is in progress. Please try again later.', { type: 'info' });
      return true;

    case fileStatuses.GENERATION_ERROR:
      notify(error || 'An error occurred while generating the CSV file.', {
        type: 'error',
      });
      return true;

    case fileStatuses.NOT_EXIST:
    default:
      notify(error || 'File not found.', { type: 'warning' });
      return true;
  }
}

export const handleDownloadCsv = async ({ rowCount, fileName, notify }) => {
  if (rowCount === 0) {
    notify('No data found for these filters. Cannot download a CSV.', { type: 'info' });
    return;
  }

  if (!fileName) {
    notify('File is not yet ready to download. Please try again later.', { type: 'warning' });
    return;
  }

  const { isFile, blob, data } = await fetchCsvOrStatus(fileName);

  if (!isFile) {
    handleFileStatus({
      fileStatus: data?.result?.fileStatus,
      error: data?.error,
      notify,
    });
    return;
  }

  downloadFileFromBlob(blob, fileName);
};
