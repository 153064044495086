import {
   List,
   Datagrid,
   TextField,
   FunctionField,
} from 'react-admin'
import { RowsPerPage, DateInOneRow, EntityIdWithName } from '../../components'
import { useRowStyle } from '../../hooks/useRowStyle'
import { useRedirectIfNoFilter } from '../../hooks/useRedirectIfNoFilter'
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle'
import { BulkActionCopy } from '../../components/BulkActionCopy'
import { compareAggregateValue } from '../../utils/aggregateTablesUtils'
import { goalsFilters } from '../../utils/filters/goalsFilters'
import { REQUESTS_ROUTES } from '../../config/constants/apiRoutes';

export const GoalList = props => {
   const rowClassName = useRowStyle()
   const shouldRedirect = compareAggregateValue('goals_list');

   useRedirectIfNoFilter({
      filterKey: 'offer_id',
      redirectLink: 'goals-aggregated',
      sourceKey: 'RaStore.goals.listParams',
      targetKey: 'RaStore.goals-aggregated.listParams',
      shouldRedirect
   });

   return (
      <List
         {...props}
         perPage={25}
         pagination={<RowsPerPage/>}
         filters={goalsFilters}
         className="list-admin-wrapper"
         actions={<ToolBarRowStyleToggle tableName='goals' showRefreshEntitiesButton />}
      >
         <Datagrid
            rowClick="edit"
            className={`${rowClassName} datagrid-content`}
            bulkActionButtons={<BulkActionCopy/>}
         >
            <TextField source="id" label="ID"/>
            <TextField source="title" label="Title"/>
            <TextField source="event_type" label="Event type"/>
            <EntityIdWithName
               source="affiliate_id"
               reference={REQUESTS_ROUTES.GET_ACTIVE_AFFILIATES}
               targetSource="affiliate_name"
            />
            <EntityIdWithName
               source="offer_id"
               reference={REQUESTS_ROUTES.GET_ALL_OFFERS}
               targetSource="offer_name"
            />
            <FunctionField
               label="Postback URL"
               render={record => 
                  <div style={{ width: '230px', wordWrap: 'break-word' }}>
                     <a href={record.postback_url} target="_blank" rel="noopener noreferrer">
                        {record.postback_url}
                     </a>
                  </div>
               }
            />
            <DateInOneRow source="update_time" label="Update time" showTime/>
         </Datagrid>
      </List>
   )
}
