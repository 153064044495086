import React from 'react'
import { RowsPerPage, BulkActionCopy, DateInOneRow } from '../../components'
import {
   BooleanField,
   Datagrid,
   TextField,
   List,
   FunctionField,
} from 'react-admin'
import cronstrue from 'cronstrue'
import { ToolBarRowStyleToggle } from '../../components/ToolBarRowStyleToggle'
import { useRowStyle } from '../../hooks/useRowStyle'

export const CronSettingsList = props => {
   const rowClassName = useRowStyle()

   return (
      <List {...props}
            pagination={<RowsPerPage/>}
            className="list-admin-wrapper"
            actions={<ToolBarRowStyleToggle/>}
            filters={[]}
            perPage={50}
      >
         <Datagrid rowClick="edit" className={`${rowClassName} datagrid-content`} bulkActionButtons={<BulkActionCopy/>}>
            <TextField source="id" label="ID"/>
            <TextField source="cron_name" label="Cron Name"/>
            <BooleanField source="is_enabled" label="Is Enabled"/>
            <FunctionField label="Cron run time"
                           render={({time_expression}) => cronstrue.toString(time_expression, {
                              verbose: true,
                              use24HourTimeFormat: true,
                           })}
            />
            <DateInOneRow source="update_time" label="Update time" showTime={true}/>
            <TextField source="current_id" label="Current ID"/>
            <TextField source="last_id" label="Last ID"/>
         </Datagrid>
      </List>
   )
}
